
import { Getter } from "vuex-class";
import { Component, Prop, Vue } from "vue-property-decorator";
import UserImage from "@/components/general/user-image.vue";
import { TeamFunctions } from "@/store/modules/activity-account.store";

@Component({
    components: {
        UserImage,
    },
})
export default class PartContactSidebar extends Vue {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("activity/viewing") activity!: Activity;

    @Prop() visible!: boolean;

    get teamMembers() {
        if (!this.activity) {
            return [];
        }

        return this.activity.assigned_to;
    }

    get salesContact() {
        if (!this.teamMembers.length) {
            return null;
        }

        const salesContact = this.teamMembers.find((tm: any) => tm.function_title === TeamFunctions.buyer); // TODO: this used to be agent but changed to buyer. is it OK?
        if (!salesContact) {
            return this.teamMembers[0];
        }

        return salesContact;
    }

    handleClosed() {
        this.$emit("close");
    }
}
